import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// Funzione per caricare il CSV
async function loadCSV() {
    try {
        const response = await fetch('/data.csv');
        const data = await response.text();

        // Debugging: verifica che stiamo caricando il file CSV giusto
        console.log("CSV Data:", data);

        if (data.includes('<!DOCTYPE html>')) {
            throw new Error('File CSV non trovato, verificare il percorso.');
        }

        return parseCSV(data);
    } catch (error) {
        console.error("Error loading CSV:", error);
    }
}

// Funzione per parsare il CSV
function parseCSV(data) {
    const rows = data.split('\n').filter(row => row.trim().length > 0);

    // Debugging: verifica che stiamo parsando correttamente le righe del CSV
    console.log("Rows:", rows);

    const headers = rows[0].split(',').map(header => header.trim());

    // Debugging: verifica che le intestazioni siano corrette
    console.log("Headers:", headers);

    const tempIndex = headers.indexOf('Temperature');

    if (tempIndex === -1) {
        console.error('Temperature column not found in CSV');
        return [];
    }

    const temperatures = rows.slice(1).map(row => {
        const cols = row.split(',');
        const temp = parseFloat(cols[tempIndex].trim());
        return isNaN(temp) ? 0 : temp; // Gestione di eventuali NaN
    });

    return temperatures;
}

loadCSV().then(temperatures => {
    console.log("Temperatures:", temperatures); // Debugging: output delle temperature
    createScene(temperatures);
});

function createScene(temperatures) {
    // Configura la scena di Three.js
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    document.body.appendChild(renderer.domElement);

    // Aggiungi controllo dell'orbita
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.target.set(0, 0, 0);
    controls.update();

    // Aggiungi luci
    const ambientLight = new THREE.AmbientLight(0x404040);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    directionalLight.position.set(0, 30, 0);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 2048;
    directionalLight.shadow.mapSize.height = 2048;
    directionalLight.shadow.camera.near = 0.5;
    directionalLight.shadow.camera.far = 50;
    directionalLight.shadow.camera.left = -20;
    directionalLight.shadow.camera.right = 20;
    directionalLight.shadow.camera.top = 20;
    directionalLight.shadow.camera.bottom = -20;
    scene.add(directionalLight);

    // Aggiungi helper per la luce
    const lightHelper = new THREE.DirectionalLightHelper(directionalLight);
    scene.add(lightHelper);

    // Aggiungi helper per la shadow camera
    // const shadowCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
    // scene.add(shadowCameraHelper);

    // Aggiungi piano
    const planeGeometry = new THREE.PlaneGeometry(100, 100);
    const planeMaterial = new THREE.MeshStandardMaterial({ color: 0x808080, side: THREE.DoubleSide });
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.rotation.x = - Math.PI / 2;
    plane.position.y = -10;
    plane.receiveShadow = true;
    scene.add(plane);

    // Aggiungi sfere rappresentanti le temperature
    const gridSize = Math.ceil(Math.sqrt(temperatures.length));
    const spacing = 1.5;

    temperatures.forEach((temp, index) => {
        const geometry = new THREE.SphereGeometry(0.3, 32, 32);
        // const material = new THREE.MeshStandardMaterial({ color: getColorForTemperature(temp, temperatures), metalness: 0.7, roughness: 0.1 });
        const material = new THREE.MeshPhysicalMaterial({
            metalness: 0.0,
            roughness: 0.1,
            clearcoat: 1.0,
            clearcoatRoughness: 0.1,
            reflectivity: 0.5,
            transmission: 1.0,
            opacity: 0.75,
            transparent: true,
            envMapIntensity: 1.0,
            color: getColorForTemperature(temp, temperatures)
        });
        const sphere = new THREE.Mesh(geometry, material);

        sphere.castShadow = true;

        const row = Math.floor(index / gridSize);
        const col = index % gridSize;

        sphere.position.x = (col - gridSize / 2) * spacing;
        sphere.position.y = temp;
        sphere.position.z = (row - gridSize / 2) * spacing;

        scene.add(sphere);
    });

    camera.position.set(0, 15, 30);
    camera.lookAt(0, 0, 0);

    // Funzione di animazione
    let angle = 0;
    function animate() {
        requestAnimationFrame(animate);
        controls.update();

        angle += 0.01;
        directionalLight.position.x = 0 * Math.cos(angle);
        directionalLight.position.z = 15 * Math.sin(angle);
        // Movimento circolare della luce
        lightHelper.update();
        // shadowCameraHelper.update();

        renderer.render(scene, camera);
    }
    animate();

    window.addEventListener('resize', () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    });
}

// Funzione per determinare il colore in base alla temperatura
function getColorForTemperature(temp, temperatures) {
    const minTemp = Math.min(...temperatures);
    const maxTemp = Math.max(...temperatures);
    const normalizedTemp = (temp - minTemp) / (maxTemp - minTemp);
    return new THREE.Color(`hsl(${(1 - normalizedTemp) * 240}, 100%, 50%)`);
}
